import React, { Component } from 'react';
import { Link } from 'gatsby';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <main className="pa4 black-80">
        <fieldset className="ba b--transparent ph0 mh0">
          <form className="measure center" onSubmit={this.onSubmit}>
            <h3>Change password</h3>
            <input
              className="mt3 pa2 input-reset ba bg-transparent w-100"
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
            <button
              className="b ph3 mt3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib"
              disabled={isInvalid} type="submit">
              Reset My Password
            </button>
            {error && <p>{error.message}</p>}
          </form>
        </fieldset>
      </main>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default withFirebase(PasswordForgetForm);

export { PasswordForgetLink };
